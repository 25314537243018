<template>
  <form-field-text
    v-model="sValue"
    :label="label"
    :mask="sMask"
    :hint="sCardName"
    :persistent-hint="!!sCardName"
    :hide-details="hideDetails"
    @save="save"
    @input="onChangeValue"
    @input:debounce="onChangeDebounce"
    v-bind="$attrs"
  >
    <template #append>
      <component :is="sIcon" size="1.8em" />
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel } from "vue-property-decorator";
import FormFieldText from "@/components/form/fields/Text.vue";
import creditCardType from "credit-card-type";

@Component({
  components: { FormFieldText },
})
export default class FormFieldCreditCard extends Vue {
  @VModel({ type: String }) sValue!: string;
  @Prop({ type: String, default: "number" }) readonly label!: string;
  @Prop({ type: [String, Boolean], default: "auto" }) readonly hideDetails!:
    | boolean
    | string;

  save(sValue: string) {
    this.$emit("save", sValue.replace(/\s+/g, ""));
  }

  onChangeValue(sVal: string) {
    this.$emit("input", sVal);
  }

  onChangeDebounce(sVal: string) {
    this.$emit("input:debounce", sVal);
  }

  get obCardInfo() {
    return this.sValue && this.sValue.length > 4
      ? this.$_.first(creditCardType(this.sValue))
      : null;
  }

  get sCardName() {
    return this.obCardInfo && (!this.hideDetails || this.hideDetails == "auto")
      ? this.obCardInfo.niceType
      : null;
  }

  get sIcon() {
    const arTypes = [
      "amex",
      "diners",
      "discover",
      "elo",
      "jcb",
      "maestro",
      "mastercard",
      "visa",
    ];
    const sCardType = this.obCardInfo ? this.obCardInfo.type : null;
    const sIcon =
      sCardType && arTypes.includes(sCardType) ? sCardType : "generic";

    return `icon-${sIcon}`;
  }

  get sMask() {
    return "#### #### #### ####";
  }
}
</script>
