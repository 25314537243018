<template>
  <ValidationProvider
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    ref="fieldBank"
    slim
  >
    <v-select
      v-model="iBankId"
      v-bind="$attrs"
      slot-scope="{ errors, valid }"
      :error-messages="errors"
      :success="valid"
      :label="$t(label)"
      :items="items"
      :menu-props="{ offsetY: true }"
      @change="onChange"
      item-value="id"
      item-text="name"
      hide-details="auto"
      clearable
      outlined
      dense
    ></v-select>
  </ValidationProvider>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import { Vue, Component, Prop, VModel } from "vue-property-decorator";

@Component
export default class BankSelect extends Vue {
  @VModel({ type: Number }) iBankId!: number;
  @Prop({ type: String, default: "bank" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly autoassign!: boolean;

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    if (this.obCollection.isEmpty()) {
      await AppModule.loadBanks();
    }

    if (this.autoassign && !this.iBankId && this.obCollection.length) {
      const obModel = this.obCollection.first();
      if (obModel) {
        this.$emit("input", obModel.id);
      }
    }
  }

  onChange(iValue: number) {
    const obCurrencyRate = this.obCollection.find({ id: iValue });
    this.$emit("change", obCurrencyRate);
  }

  get obCollection() {
    return AppModule.banks;
  }

  get items() {
    return this.obCollection.models;
  }

  get loading() {
    return this.obCollection.loading;
  }
}
</script>
